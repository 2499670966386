import * as React from "react";
import { FaSpinner } from "react-icons/fa";

interface Props {
  style?: React.CSSProperties;
}

const Spinner: React.SFC<Props> = ({ style }: Props) => (
  <div style={style || {}}>
    <FaSpinner className="papio-spinner" />
  </div>
);

export default Spinner;
