import { defaultImage } from "./constants";

export function cloudinaryFaceTransformer(
  url: string,
  width = 275,
  height = 275
): string {
  const substringToAdd = `w_${width},h_${height},c_fill,g_auto/`;
  if (!url.includes("res.cloudinary.com")) {
    return url;
  }
  const split = "/image/upload/";
  const splits = url.split(split);
  return `${splits[0]}${split}${substringToAdd}${splits[1]}`;
}

export const getImage = (maybeImage: string | null): string =>
  cloudinaryFaceTransformer(maybeImage || defaultImage);
